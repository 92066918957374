<!--
 * @Author: your name
 * @Date: 2021-04-21 15:40:26
 * @LastEditTime: 2021-04-27 16:35:23
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \yaaami-frontend-user\src\App.vue
-->
<template>
  <router-view v-slot="{ Component }">
    <keep-alive>
      <component :is="Component" v-if="$route.meta.keepAlive" />
    </keep-alive>
    <component :is="Component" v-if="!$route.meta.keepAlive" />
  </router-view>
</template>
<script>

export default {
  setup() {
  }
}
</script>
<style lang="less">
  @import 'common/style/mixin';
  @import 'common/style/base';
html, body {
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  background: #f4f5f7;
}
#app {
  height: 100%;
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;

}
@media screen and (min-width: 1000px) {
    #app {
      width: 10rem;
      margin: auto;
    }
    .nav-bar {
      width: 10rem !important;
      margin: auto;
      left: auto !important;
    }
    .van-action-bar {
      width: 10rem !important;
      margin: auto;
      left: auto !important;
      right: auto !important;
    }
    .van-address-list__bottom {
      width: 10rem !important;
      margin: auto;
      left: auto !important;
      right: auto !important;
    }
    .van-submit-bar__bar {
      width: 10rem !important;
      margin: auto;
      left: auto !important;
      right: auto !important;
      box-sizing: border-box;
    }
    .van-submit-bar {
      width: 10rem !important;
      margin: auto;
      left: auto !important;
      right: auto !important;
    }
}

</style>
